<div class="container">
  <form [formGroup]="formGroup" (ngSubmit)="handleSubmit()">
    <h1 class="mt-5 mb-3">{{ 'OWNERSHIP_CONVERSION.TITLE' | translate }}</h1>

    <section>
      <div
        class="card mb-4"
        [ngClass]="
          !isLawPersonFormGroupValid() && submitted
            ? 'border-danger border-2'
            : 'border-dark'
        "
      >
        <div class="card-header text-white bg-dark">
          {{ 'OWNERSHIP_CONVERSION.CLIENT_DATA' | translate }}
        </div>

        <div class="card-body">
          <gk-law-person-simple-form
            [lawPersonFormGroup]="getLawPersonFormGroup()"
          >
          </gk-law-person-simple-form>
        </div>
      </div>
    </section>

    <section>
      <div
        class="card mb-4"
        [ngClass]="
          !areLandParcelsChosen() && submitted
            ? 'border-danger border-2'
            : 'border-dark'
        "
      >
        <div class="card-header text-white bg-dark">
          {{ 'OWNERSHIP_CONVERSION.MAP_LOCATION' | translate }}
        </div>

        <div class="card-body">
          <gk-map
            [mapState]="mapState"
            (dispatch)="handleMapAction($event)"
          ></gk-map>

          <div class="my-2">
            @if (mapState.mapObjectTablesState[0].mapObjects.length) {
              <gk-map-object-table
                [mapState]="mapState"
                [mapObjectTableStateIndex]="0"
                (dispatch)="handleMapAction($event)"
              ></gk-map-object-table>
            }
          </div>
        </div>

        @if (submitted && !areLandParcelsChosen()) {
          <div class="card-footer">
            <div class="alert alert-danger mt-2">
              {{ 'OWNERSHIP_CONVERSION.LAND_PARCEL_REQUIRED' | translate }}
            </div>
          </div>
        }
      </div>
    </section>

    <app-attachments [portalId]="portalId" [submitted]="submitted">
    </app-attachments>

    <section class="card border-dark mb-4">
      <div class="card-header text-white bg-dark">
        {{ 'OWNERSHIP_CONVERSION.DOCUMENTATION_FORM' | translate }}
      </div>

      <div class="card-body">
        <div class="form-check form-check-inline">
          <input
            type="radio"
            [id]="controlName.DecisionForm"
            [attr.name]="controlName.DecisionForm"
            [formControlName]="controlName.DecisionForm"
            [value]="decisionFormType.NonElectronicForm"
            class="form-check-input"
          />

          <label class="form-check-label" [attr.for]="controlName.DecisionForm">
            {{ 'OWNERSHIP_CONVERSION.NON_ELECTRONIC_FORM' | translate }}
          </label>
        </div>
      </div>
    </section>

    @if (portalId) {
      <section
        class="card mb-4"
        [ngClass]="
          checkShippingFormGroupValidAndFormSubmitted()
            ? 'border-danger border-2'
            : 'border-dark'
        "
      >
        <div class="card-header text-white bg-dark">
          {{ 'OWNERSHIP_CONVERSION.SHIPPING_FORM' | translate }}
        </div>
        <div class="card-body">
          <app-shipping-form
            [shippingFormGroup]="getShippingFormGroup()"
            [decisionForm]="decisionFormValue"
            [removeDisabledControls]="true"
            [portalId]="portalId"
          ></app-shipping-form>
        </div>
        @if (checkShippingFormGroupValidAndFormSubmitted()) {
          <div class="card-footer">
            <div class="alert alert-danger">
              {{ 'OWNERSHIP_CONVERSION.SHIPPING_FORM_REQUIRED' | translate }}
            </div>
          </div>
        }
      </section>
    }

    <section>
      <div class="card mb-4 border-dark">
        <div class="card-header text-white bg-dark">
          <label class="mb-0" [attr.for]="controlName.Comments">
            {{ 'OWNERSHIP_CONVERSION.ADDITIONAL_INFORMATION' | translate }}
          </label>
        </div>

        <div class="card-body">
          <div class="row">
            <div class="col-12">
              <textarea
                [name]="controlName.Comments"
                [id]="controlName.Comments"
                class="form-control form-control-sm"
                [formControlName]="controlName.Comments"
              ></textarea>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="my-2">
      @if (submitted && !isRequestValid()) {
        <div class="alert alert-danger mt-1">
          <span>{{
            'OWNERSHIP_CONVERSION.PROVIDE_REQUIRED_FIELDS' | translate
          }}</span>
        </div>
      }

      @if (errorSubmitMessage) {
        <div class="alert alert-danger mt-2">
          <span>{{ errorSubmitMessage }}</span>
        </div>
      }
    </section>

    <section class="my-2">
      <app-cautions
        [portalId]="portalId"
        [formGroup]="formGroup"
        [submitted]="submitted"
      ></app-cautions>
    </section>

    <section>
      <button type="submit" class="btn btn-primary">
        {{ 'OWNERSHIP_CONVERSION.SUBMIT' | translate }}
      </button>

      <app-request-cancel-button></app-request-cancel-button>

      <div class="d-flex flex-column">
        @if (docSignMsgTxt) {
          <span [ngClass]="'text-' + docSignMsgType">
            {{ docSignMsgTxt }}
          </span>
        }

        @if (docSignUrl) {
          <span class="my-2 text-info">
            {{ docSignTranslations['SIGN_INFO'] }}
          </span>
        }

        @if (docSignUrl) {
          <a class="link" [href]="docSignUrl" target="_blank">
            {{ docSignTranslations['SIGN'] }}
          </a>
        }

        @if (docSignPending) {
          <div class="d-flex justify-content-center my-3">
            <div class="spinner-border" role="status">
              <span class="visually-hidden">{{ 'LOADING' | translate }}</span>
            </div>
          </div>
        }
      </div>

      <div class="mt-2"><app-form-legend></app-form-legend></div>
    </section>
  </form>
</div>
