<div class="container">
  <form [formGroup]="formGroup" (ngSubmit)="handleSubmit()">
    <h1 class="mt-5 mb-3">
      {{ 'LANE_OCCUPANCY_PORTAL.INFRASTRUCTURE_DEVICES.TITLE' | translate }}
    </h1>

    <section>
      <div
        class="card mb-4"
        [ngClass]="
          !isLawPersonFormGroupValid() && submitted
            ? 'border-danger border-2'
            : 'border-dark'
        "
      >
        <div class="card-header text-white bg-dark">
          {{
            'LANE_OCCUPANCY_PORTAL.INFRASTRUCTURE_DEVICES.CLIENT_DATA'
              | translate
          }}
        </div>

        <div class="card-body">
          <gk-law-person-simple-form
            [lawPersonFormGroup]="getLawPersonFormGroup()"
          >
          </gk-law-person-simple-form>
        </div>
      </div>
    </section>

    <section>
      <div
        class="card mb-4"
        [ngClass]="
          !isLaneOccupancyPurposeFormControlValid() && submitted
            ? 'border-danger border-2'
            : 'border-dark'
        "
      >
        <div class="card-header text-white bg-dark">
          <label class="mb-0" [attr.for]="controlName.LaneOccupancyPurpose">
            {{
              'LANE_OCCUPANCY_PORTAL.INFRASTRUCTURE_DEVICES.OCCUPANCY_PURPOSE'
                | translate
            }}
          </label>
        </div>

        <div class="card-body">
          <textarea
            [name]="controlName.LaneOccupancyPurpose"
            [id]="controlName.LaneOccupancyPurpose"
            class="form-control required"
            aria-required="true"
            [formControlName]="controlName.LaneOccupancyPurpose"
          ></textarea>
        </div>

        @if (shouldShowRequiredAlert(getLaneOccupancyPurposeFormControl())) {
          <div class="card-footer">
            <div class="alert alert-danger">
              {{ 'FIELD_REQUIRED' | translate }}
            </div>
          </div>
        }
      </div>
    </section>

    <section>
      <div
        class="card mb-4"
        [ngClass]="
          !isLocationFormGroupValid() && submitted
            ? 'border-danger border-2'
            : 'border-dark'
        "
      >
        <div class="card-header text-white bg-dark">
          {{
            'LANE_OCCUPANCY_PORTAL.INFRASTRUCTURE_DEVICES.LOCATION' | translate
          }}
        </div>

        <div class="card-body">
          <div class="mb-2">
            <label class="text-nowrap" [attr.for]="controlName.RoadNumber">
              {{
                'LANE_OCCUPANCY_PORTAL.INFRASTRUCTURE_DEVICES.ROAD_NUMBER'
                  | translate
              }}
            </label>

            <input
              type="text"
              [name]="controlName.RoadNumber"
              [id]="controlName.RoadNumber"
              class="form-control form-control-sm required"
              aria-required="true"
              [formControlName]="controlName.RoadNumber"
            />

            @if (shouldShowRequiredAlert(getRoadNumberFormControl())) {
              <div class="alert alert-danger">
                {{ 'FIELD_REQUIRED' | translate }}
              </div>
            }
          </div>

          <div class="mb-2">
            <label class="text-nowrap" [attr.for]="controlName.Place">
              {{
                'LANE_OCCUPANCY_PORTAL.INFRASTRUCTURE_DEVICES.PLACE' | translate
              }}
            </label>

            <input
              type="text"
              [name]="controlName.Place"
              [id]="controlName.Place"
              class="form-control form-control-sm required"
              aria-required="true"
              [formControlName]="controlName.Place"
              [ngbTypeahead]="searchPlace"
              [resultFormatter]="formatter"
              [inputFormatter]="formatter"
              (focusout)="setPlaceByName()"
            />

            @if (shouldShowRequiredAlert(getPlaceFormControl())) {
              <div class="alert alert-danger">
                {{ 'FIELD_REQUIRED' | translate }}
              </div>
            }
          </div>

          <div class="mb-2">
            <label class="text-nowrap" [attr.for]="controlName.Street">
              {{
                'LANE_OCCUPANCY_PORTAL.INFRASTRUCTURE_DEVICES.STREET'
                  | translate
              }}
            </label>

            <input
              type="text"
              [name]="controlName.Street"
              [id]="controlName.Street"
              class="form-control form-control-sm"
              [ngbTypeahead]="searchStreet"
              [resultFormatter]="formatter"
              [inputFormatter]="formatter"
              [formControlName]="controlName.Street"
              (focusout)="setStreetByName()"
              [autocomplete]="undefined"
            />
          </div>
        </div>

        @if (submitted && !areLaneLocationIssueRangesChosen()) {
          <div class="card-footer">
            <div class="alert alert-danger mt-2">
              {{
                'LANE_OCCUPANCY_PORTAL.INFRASTRUCTURE_DEVICES.ISSUE_RANGE_REQUIRED'
                  | translate
              }}
            </div>
          </div>
        }
      </div>
    </section>

    <section>
      <div
        class="card mb-4"
        [ngClass]="
          !isAreaFormGroupValid() && submitted
            ? 'border-danger border-2'
            : 'border-dark'
        "
      >
        <div class="card-header text-white bg-dark">
          {{
            'LANE_OCCUPANCY_PORTAL.INFRASTRUCTURE_DEVICES.AREA_TITLE'
              | translate
          }}
        </div>

        <div class="card-body">
          <gk-map
            [mapState]="mapState"
            (dispatch)="handleLaneLocationMapAction($event)"
          ></gk-map>

          <div class="my-2">
            @if (mapState.mapObjectTablesState[0].mapObjects.length) {
              <gk-map-object-table
                [mapState]="mapState"
                [mapObjectTableStateIndex]="0"
                (dispatch)="handleLaneLocationMapAction($event)"
              ></gk-map-object-table>
            }
          </div>

          @if (calculatedArea) {
            <div class="mb-2 row">
              <div class="col-auto pe-0">
                <label class="text-nowrap" for="calculated-area">
                  {{
                    'LANE_OCCUPANCY_PORTAL.INFRASTRUCTURE_DEVICES.CALCULATED_AREA_LABEL'
                      | translate
                  }}
                </label>
              </div>
              <div class="col-auto ps-0">
                <div class="input-group input-group-sm ps-2">
                  <input
                    type="number"
                    name="calculated-area"
                    [defaultValue]="calculatedArea"
                    id="calculated-area"
                    class="form-control"
                    readonly
                  />
                  <span class="input-group-text">m²</span>
                </div>
              </div>
            </div>
          }

          <div class="mb-2 row">
            <div class="col-auto pe-0">
              <label
                class="text-nowrap"
                [attr.for]="controlName.AreaWithoutRoadEngineeringFacilities"
              >
                {{
                  'LANE_OCCUPANCY_PORTAL.INFRASTRUCTURE_DEVICES.AREA_WITHOUT_ROAD_ENGINEERING_FACILITIES'
                    | translate
                }}
              </label>
            </div>
            <div class="col-auto ps-0">
              <div class="input-group input-group-sm ps-2">
                <input
                  type="number"
                  [name]="controlName.AreaWithoutRoadEngineeringFacilities"
                  [id]="controlName.AreaWithoutRoadEngineeringFacilities"
                  class="form-control"
                  [formControlName]="
                    controlName.AreaWithoutRoadEngineeringFacilities
                  "
                />

                <span class="input-group-text">m²</span>
              </div>
            </div>
          </div>

          <div class="mb-2 row">
            <div class="col-auto pe-0">
              <label
                class="text-nowrap"
                [attr.for]="controlName.AreaOfRoadEngineeringFacilities"
              >
                {{
                  'LANE_OCCUPANCY_PORTAL.INFRASTRUCTURE_DEVICES.AREA_OF_ROAD_ENGINEERING_FACILITIES'
                    | translate
                }}
              </label>
            </div>
            <div class="col-auto ps-0">
              <div class="input-group input-group-sm ps-2">
                <input
                  type="number"
                  [name]="controlName.AreaOfRoadEngineeringFacilities"
                  [id]="controlName.AreaOfRoadEngineeringFacilities"
                  class="form-control"
                  [formControlName]="
                    controlName.AreaOfRoadEngineeringFacilities
                  "
                />

                <span class="input-group-text">m²</span>
              </div>
            </div>
          </div>
        </div>

        @if (submitted && !isAreaFormGroupValid()) {
          <div class="card-footer">
            @if (!areLaneLocationIssueRangesChosen()) {
              <div class="alert alert-danger mt-2">
                {{
                  'LANE_OCCUPANCY_PORTAL.INFRASTRUCTURE_DEVICES.ISSUE_RANGE_REQUIRED'
                    | translate
                }}
              </div>
            }
            @if (!areAreaControlsValid()) {
              <div class="alert alert-danger mt-2">
                {{
                  'LANE_OCCUPANCY_PORTAL.INFRASTRUCTURE_DEVICES.AREA_REQUIRED'
                    | translate
                }}
              </div>
            }
          </div>
        }
      </div>
    </section>

    <section>
      <div
        class="card mb-4"
        [ngClass]="
          isDateRangeInvalid() && submitted
            ? 'border-danger border-2'
            : 'border-dark'
        "
      >
        <div class="card-header text-white bg-dark">
          <label class="mb-0" [attr.for]="controlName.LaneOccupancyDate">
            {{
              'LANE_OCCUPANCY_PORTAL.INFRASTRUCTURE_DEVICES.LANE_OCCUPANCY_DATE'
                | translate
            }}
          </label>
        </div>

        <div class="card-body">
          <app-date-range-picker
            (dateRangeChanged)="updateDateRangeFormControl($event)"
            [required]="true"
          ></app-date-range-picker>
        </div>

        @if (isDateRangeInvalid() && submitted) {
          <div class="card-footer">
            <div class="alert alert-danger">
              {{ 'PERIOD_INVALID' | translate }}
            </div>
          </div>
        }
      </div>
    </section>

    <app-attachments [portalId]="portalId" [submitted]="submitted">
    </app-attachments>

    <section class="card border-dark mb-4">
      <div class="card-header text-white bg-dark">
        {{
          'LANE_OCCUPANCY_PORTAL.INFRASTRUCTURE_DEVICES.DOCUMENTATION_FORM'
            | translate
        }}
      </div>

      <div class="card-body">
        <div class="form-check form-check-inline">
          <input
            type="radio"
            [id]="decisionFormType.NonElectronicForm"
            [attr.name]="controlName.DecisionForm"
            [formControlName]="controlName.DecisionForm"
            [value]="decisionFormType.NonElectronicForm"
            class="form-check-input"
          />

          <label
            class="form-check-label"
            [attr.for]="decisionFormType.NonElectronicForm"
          >
            {{
              'LANE_OCCUPANCY_PORTAL.INFRASTRUCTURE_DEVICES.NON_ELECTRONIC_FORM'
                | translate
            }}
          </label>
        </div>
      </div>
    </section>

    @if (portalId) {
      <section
        class="card mb-4"
        [ngClass]="
          checkShippingFormGroupValidAndFormSubmitted()
            ? 'border-danger border-2'
            : 'border-dark'
        "
      >
        <div class="card-header text-white bg-dark">
          {{
            'LANE_OCCUPANCY_PORTAL.INFRASTRUCTURE_DEVICES.SHIPPING_FORM'
              | translate
          }}
        </div>
        <div class="card-body">
          <app-shipping-form
            [shippingFormGroup]="getShippingFormGroup()"
            [decisionForm]="decisionFormValue"
            [removeDisabledControls]="true"
            [portalId]="portalId"
          ></app-shipping-form>
        </div>
        @if (checkShippingFormGroupValidAndFormSubmitted()) {
          <div class="card-footer">
            <div class="alert alert-danger">
              {{
                'LANE_OCCUPANCY_PORTAL.BUILDING_OBJECTS.SHIPPING_FORM_REQUIRED'
                  | translate
              }}
            </div>
          </div>
        }
      </section>
    }

    <section>
      <div class="card border-dark mb-4">
        <div class="card-header text-white bg-dark">
          {{
            'LANE_OCCUPANCY_PORTAL.INFRASTRUCTURE_DEVICES.COMMENTS_LABEL'
              | translate
          }}
        </div>

        <div class="card-body">
          <P
            [innerHTML]="
              'LANE_OCCUPANCY_PORTAL.INFRASTRUCTURE_DEVICES.COMMENTS'
                | translate
            "
          >
          </P>
        </div>
      </div>
    </section>

    <section>
      <div class="card border-dark mb-4">
        <div class="card-header text-white bg-dark">
          {{
            'LANE_OCCUPANCY_PORTAL.INFRASTRUCTURE_DEVICES.PERSONAL_DATA_PROCESING_LABEL'
              | translate
          }}
        </div>

        <div class="card-body">
          <P
            [innerHTML]="
              'LANE_OCCUPANCY_PORTAL.INFRASTRUCTURE_DEVICES.PERSONAL_DATA_PROCESING'
                | translate
            "
          >
          </P>
        </div>
      </div>
    </section>

    <section class="my-2">
      @if (submitted && !isRequestValid()) {
        <div class="alert alert-danger mt-1">
          <span>
            {{
              'LANE_OCCUPANCY_PORTAL.INFRASTRUCTURE_DEVICES.PROVIDE_REQUIRED_FIELDS'
                | translate
            }}
          </span>
        </div>
      }

      @if (errorSubmitMessage) {
        <div class="alert alert-danger mt-2">
          <span>{{ errorSubmitMessage }}</span>
        </div>
      }
    </section>

    <section>
      <button type="submit" class="btn btn-primary">
        {{ 'LANE_OCCUPANCY_PORTAL.INFRASTRUCTURE_DEVICES.SUBMIT' | translate }}
      </button>

      <app-request-cancel-button></app-request-cancel-button>

      <div class="d-flex flex-column">
        @if (docSignMsgTxt) {
          <span [ngClass]="'text-' + docSignMsgType">
            {{ docSignMsgTxt }}
          </span>
        }

        @if (docSignUrl) {
          <span class="my-2 text-info">
            {{ docSignTranslations['SIGN_INFO'] }}
          </span>
        }

        @if (docSignUrl) {
          <a class="link" [href]="docSignUrl" target="_blank">
            {{ docSignTranslations['SIGN'] }}
          </a>
        }

        @if (docSignPending) {
          <div class="d-flex justify-content-center my-3">
            <div class="spinner-border" role="status">
              <span class="visually-hidden">{{ 'LOADING' | translate }}</span>
            </div>
          </div>
        }
      </div>

      <div class="mt-2"><app-form-legend></app-form-legend></div>
    </section>
  </form>
</div>

<ng-template #canDeactivateModal let-close="close">
  <div class="modal-header">
    <h4 class="modal-title">
      {{ 'MODAL_CAN_DEACTIVATE.ALERT_TITLE' | translate }}
    </h4>
  </div>

  <div class="modal-body">
    {{ 'MODAL_CAN_DEACTIVATE.DESCRIPTION' | translate }}
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-light" (click)="close(false)">
      {{ 'MODAL_CAN_DEACTIVATE.CANCEL' | translate }}
    </button>

    <button type="button" class="btn btn-primary" (click)="close(true)">
      {{ 'MODAL_CAN_DEACTIVATE.CONFIRM' | translate }}
    </button>
  </div>
</ng-template>
